@import "sanitize.css";

:root {
  /* colors */
  --color-danger: rgba(234, 26, 151, 1);
  --color-danger-800: rgba(234, 26, 151, 0.8);
  --color-danger-darker: rgb(197, 3, 119);
  --color-selection: rgba(0, 244, 141, 1);
  --color-focus: var(--color-black-1000);
  --color-white: white;
  --color-black: black;
  --color-gray-400: rgba(238, 238, 238, 0.4);
  --color-gray-600: #f6f6f6;
  --color-gray-800: #f2f2f2;
  --color-gray-1000: rgba(238, 238, 238);
  --color-black-300: rgba(0, 0, 0, 0.3);
  --color-black-600: rgba(0, 0, 0, 0.6);
  --color-black-700: rgba(0, 0, 0, 0.7);
  --color-black-800: rgba(0, 0, 0, 0.8);

  /* Main grays */
  --color-gray-text-light: var(--color-black-600);

  /* button colors */
  --color-button-primary-bg: var(--color-black);
  --color-button-secondary-bg: var(--color-gray-600);
  --color-button-default-bg: transparent;
  --color-button-warning-bg: var(--color-danger);

  /* button hover colors */
  --color-button-primary-bg-hover: var(--color-black-800);
  --color-button-secondary-bg-hover: var(--color-gray-1000);
  --color-button-default-bg-hover: transparent;
  --color-button-warning-bg-hover: var(--color-danger-darker);

  /* button active colors */
  --color-button-primary-bg-active: rgb(75, 75, 75);
  --color-button-secondary-bg-active: #e3e3e3;
  --color-button-link-with-background-active: var(--color-gray-1000);
  --color-button-default-bg-active: transparent;
  --color-button-warning-bg-active: #ad0369;

  /* button text colors */
  --color-button-primary-fg: var(--color-white);
  --color-button-secondary-fg: var(--color-black);
  --color-button-default-fg: var(--color-black-700);
  --color-button-warning-fg: var(--color-white);

  /* text colors */
  --text-white: var(--color-white);
  --text-black: var(--color-black);
  --text-gray: var(--color-black-800);

  /* border */
  --border: 2px solid transparent;
  --border-radius: 8px;
  --border-radius-large: 14px;

  /* font sizes */
  --font-size-11: 0.6875rem;
  --font-size-14: 0.875rem;
  --font-size-16: 1rem;
  --font-size-40: 2.5rem;

  /* input field height */
  --input-height-small: 30px;
  --input-height-medium: 36px;
  --input-height-large: 48px;

  /* animation speed */
  --animation--speed1: 0.3s;
}

:root {
  background-color: var(--color-white);
}

/* whole app background color */
body {
  caret-color: var(--color-danger-darker);
  font-family: "Poppins", Helvetica, sans-serif;
  font-size: var(--font-size-16);
  font-weight: 400;
  font-variant: normal;
  line-height: 1.5;
}

::selection {
  background-color: var(--color-selection) !important;
}

::placeholder {
  color: var(--color-black-800);
}

/* Global scrollbar */
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  background-color: var(--color-white);
  border-bottom-right-radius: 100px;
  border-top-right-radius: 100px;
}

::-webkit-scrollbar-track {
  border-radius: 100px;
}

::-webkit-scrollbar-thumb {
  background-color: rgb(196, 196, 196); /* color of the scroll thumb */
  border-radius: 100px; /* roundness of the scroll thumb */
}

::-webkit-scrollbar-thumb:hover {
  background-color: var(--color-black); /* color of the scroll thumb on hover */
}

/* autofill */
:-webkit-autofill,
:-webkit-autofill:active,
:-webkit-autofill:focus,
:-webkit-autofill:hover {
  -webkit-box-shadow: 0 0 0px 40rem var(--color-gray-800) inset;
  /* outline: 2px solid transparent;
  offset: -2px;
  border: 2px solid blue; */
}
